<template>

  <div
      class="login login-signin-on login-3 d-flex flex-row-fluid"
      id="kt_login"
  >
    <div
        class="d-flex flex-center flex-row-fluid bgi-size-cover bgi-position-top bgi-no-repeat"
        style="background-image: url('/assets/media/bg/bg-3.jpg');"
    >
      <div class="login-form text-center p-7 position-relative overflow-hidden">

        <h3 class="text-primary">{{$t("LOGINREGISTERPROCESSONPUBLIC.ORGANIZATION")}}</h3>
<!--        <p class="lead text-muted font-weight-bold">-->
<!--          {{$t("LOGINREGISTERPROCESSONPUBLIC.PAYOFF") /* #key_loc */}}-->
<!--        </p>-->
        <p class="mt-6 font-weight-bolder">{{$t("ADVAUTHMETHOD.PARTECIPAPROCESSO")}}</p>

        <div class="my-0">
            <img
              :src="(getOrganization.data) ? getOrganization.data.logo1Uri : '/assets/media/bipart/logo-bipart-brand.svg'"
              style="max-height: 160px; max-width: 260px"
              alt="Logo"
            />
        </div>
        <div class="h5 mt-5 py-5 border-top border-top-2 dashed text-primary">{{ getOrganization.data.name }}</div>

        <div class="login-signup">
          <div class="mb-10">
<!--            <div class="text-muted font-weight-bold">-->
<!--              <span class="h3"> {{$t("REGISTERFROMANONYMOUS.TITOLO") /* #key_loc */}}</span>-->
<!--            </div>-->
          </div>
          <div
              v-if="!this.confirm_send"
          >
          <h3 class="text-primary my-5 font-weight-bolder">{{$t("REGISTERFROMANONYMOUS.ISCRIVITIPROCESSO")}} {{this.process.title}}</h3>
          <div class="text-muted font-weight-bold">
            {{$t("REGISTERFROMANONYMOUS.GIAACCOUNT")}}
            <router-link
                :to="{
                  name: 'registerProcessFromAnonymous',
                  params: {
                    process_id: process_id,
                  }
                }"
              class="text-primary font-weight-bolder"
              >Login</router-link>
          </div>

          <form
              class="form mt-5" @submit.stop.prevent="onSubmit">
            <div class="form-group mb-5">
              <input
                  class="form-control h-auto form-control-solid py-4 px-8"
                  type="text"
                  :placeholder="$t('REGISTER.PLACEHOLDERNOME') + ' *'"
                  name="name"
                  id="name"
                  v-model="$v.form.name.$model"
                  :state="validateState('name')"
                  aria-describedby="username-live-feedback"
                  autocomplete="off"
                  :class="{invalid: $v.form.name.$dirty && $v.form.name.$invalid}"
              />
              <template v-if="$v.form.name.$dirty && $v.form.name.$invalid">
                <span v-if="!$v.form.name.required" class="error">{{$t("REGISTERFROMANONYMOUS.REQUIREDFIELD")}}</span>
              </template>
            </div>
            <div
                class="form-group mb-5">
              <input
                  class="form-control h-auto form-control-solid py-4 px-8"
                  type="text"
                  :placeholder="$t('REGISTER.PLACEHOLDERCOGNOME') + ' *'"
                  name="surname"
                  id="surname"
                  v-model="$v.form.surname.$model"
                  :state="validateState('surname')"
                  aria-describedby="username-live-feedback"
                  autocomplete="off"
                  :class="{invalid: $v.form.surname.$dirty && $v.form.surname.$invalid}"
              />
              <template v-if="$v.form.surname.$dirty && $v.form.surname.$invalid">
                <span v-if="!$v.form.surname.required" class="error">{{$t("REGISTERFROMANONYMOUS.REQUIREDFIELD")}}</span>
              </template>
            </div>
            <div class="form-group mb-5">
              <input
                  class="form-control h-auto form-control-solid py-4 px-8"
                  type="text"
                  :placeholder="$t('REGISTER.PLACEHOLDERUTENTE') + ' *'"
                  name="username"
                  id="username"
                  v-model="$v.form.username.$model"
                  :state="validateState('username')"
                  aria-describedby="username-live-feedback"
                  autocomplete="off"
                  :class="{invalid: $v.form.username.$dirty && $v.form.username.$invalid}"
              />
              <template v-if="$v.form.username.$dirty && $v.form.username.$invalid">
                <span v-if="!$v.form.username.required" class="error">{{$t("REGISTERFROMANONYMOUS.REQUIREDFIELD")}}</span>
              </template>
            </div>
            <div class="form-group mb-5">
              <input
                  class="form-control h-auto form-control-solid py-4 px-8"
                  type="email"
                  :placeholder="$t('REGISTER.PLACEHOLDEREMAIL') + ' *'"
                  name="email"
                  id="email"
                  v-model="$v.form.email.$model"
                  :state="validateState('email')"
                  aria-describedby="username-live-feedback"
                  autocomplete="off"
                  :class="{invalid: $v.form.email.$dirty && $v.form.email.$invalid}"
              />
              <template v-if="$v.form.email.$dirty && $v.form.email.$invalid">
                <span v-if="!$v.form.email.required" class="error">{{$t("REGISTERFROMANONYMOUS.REQUIREDFIELD")}}</span>
                <span v-if="!$v.form.email.email" class="error">{{$t("REGISTERFROMANONYMOUS.VALIDEMAIL") /* #key_edit_loc */}}</span>
              </template>
            </div>
            <div class="form-group mb-5">
              <input
                  class="form-control h-auto form-control-solid py-4 px-8"
                  type="password"
                  :placeholder="$t('REGISTER.PLACEHOLDERPASSWORD') + ' *'"
                  name="password"
                  id="password"
                  v-model="$v.form.password.$model"
                  :state="validateState('password')"
                  aria-describedby="username-live-feedback"
                  autocomplete="off"
                  :class="{invalid: $v.form.password.$dirty && $v.form.password.$invalid}"
              />
              <template v-if="$v.form.password.$dirty && $v.form.password.$invalid">
                <span v-if="!$v.form.password.required" class="error">{{$t("REGISTERFROMANONYMOUS.REQUIREDFIELD")}}</span>
              </template>

            </div>
            <!-- <div class="form-group mb-5">
              <input class="form-control h-auto form-control-solid py-4 px-8" type="password" placeholder="Confirm Password" name="rpassword" />
            </div> -->
            <div class="form-group mb-5 text-left">
              <label class="checkbox m-0">
                <input type="checkbox" name="term_and_conditions" v-model="term_and_conditions" />{{$t("REGISTERFROMANONYMOUS.ACCETTO")}}
                <router-link
                    to="/terms"
                    target="_blank"
                    class="font-weight-bold">
                  {{$t("REGISTERFROMANONYMOUS.TERMINI")}}</router-link>.
                <span></span></label>
              <div class="form-text text-muted text-center"></div>
            </div>
            <div class="form-group mb-5 text-left">
              <label class="checkbox m-0">
                <input type="checkbox" name="privacy" v-model="privacy" />{{$t("REGISTERFROMANONYMOUS.ACCETTOLA")}}
                <router-link
                    href="/privacy"
                    target="_blank">
                  {{$t("REGISTERFROMANONYMOUS.PRIVACY")}}</router-link>.
                <span></span></label>
              <div class="form-text text-muted text-center"></div>
            </div>
            <div class="form-group mb-5 text-left">
              <label class="checkbox m-0">
                <input type="checkbox" name="newsletter" v-model="newsletter" />{{$t("REGISTERFROMANONYMOUS.NEWSLETTER")}}
                <span></span></label>
              <div class="form-text text-muted text-center"></div>
            </div>
            <div class="form-group d-flex flex-wrap">
              <button
                  :disabled='isDisabled'
                  class="btn btn-lg btn-primary text-uppercase font-weight-bolder"
                  id="register_anonymous_submit"
                  ref="register_anonymous_submit"
              >{{$t("REGISTERFROMANONYMOUS.BCONFERMA")}}</button>
            </div>
          </form>

<!--          <b-modal id="modal-anonymous-register" ref="modal-anonymous-register" centered hide-header hide-backdrop content-class="shadow" ok-only>-->
<!--            <div class="mt-10">-->
<!--              <h2>Grazie per esserti unito alla nostra community</h2>-->
<!--              <p class="lead">Abbiamo inviato un’email* alla tua casella di posta.<br>-->
<!--                <span class="font-weight-bolder">Confermala</span> per entrare a far parte dell’organizzazione.</p>-->
<!--              <p class="font-weight-bolder">* Attenzione! Controlla anche la cartella spam.</p>-->
<!--            </div>-->
<!--          </b-modal>-->
          </div>
          <div
              v-if="this.confirm_send"
              class="mt-10">
            <h2>{{$t("REGISTERFROMANONYMOUS.GRAZIE")}}</h2>
            <p class="lead" v-html='$t("REGISTERFROMANONYMOUS.DETTMEX")'></p>
          </div>

        </div>


      </div>
    </div>
  </div>

</template>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>

<script>
import { mapState } from "vuex";
import { mapGetters } from "vuex";
import { REGISTER } from "@/core/services/store/auth.module";

import { validationMixin } from "vuelidate";
import { email, minLength, required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import {processMixin} from "@/mixins/process";

// import ApiService from "@/core/services/api.service";
// import jwt_decode from "jwt-decode";
// import store from "@/core/services/store";

export default {
  mixins: [validationMixin, processMixin],
  name: "register",
  data() {
    return {
      process_id: this.$route.params.process_id,
      term_and_conditions: false,
      privacy:false,
      newsletter: false,
      form: {
        name: "",
        surname: "",
        username: "",
        email: "",
        password: "",
      },
      confirm_send: false,
      process: []
    };
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    ...mapGetters([
      "getOrganization"
    ]),
    isDisabled: function(){
      return !this.privacy || !this.term_and_conditions || this.confirm_send;
    }
  },
  watch: {},
  mounted() {},
  validations: {
    form: {
      name: {
        required
      },
      surname: {
        required
      },
      username: {
        required
      },
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(3)
      },
    },
  },
  created() {
    this.process = this.getProcess(this.process_id)  ;
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },

    resetForm() {
      this.form = {
        name: null,
        surname: null,
        username: null,
        email: null,
        password: null,
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },

    async onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        Swal.fire(this.$t("REGISTERFROMANONYMOUS.TITLE2"), this.$t("REGISTERFROMANONYMOUS.ERR1"), "info");
        return;
      }

      if(!this.term_and_conditions || !this.privacy) {
        return;
      }

      // set spinner to submit button
      const submitButton = this.$refs["register_anonymous_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      let registrationData = {
        username: this.$v.form.username.$model,
        password: this.$v.form.password.$model,
        email: this.$v.form.email.$model,
        name: this.$v.form.name.$model,
        surname: this.$v.form.surname.$model,
        termsConditionsAccepted: this.term_and_conditions,
        privacyPolicyAccepted: this.privacy,
        subscribedToNewsletter: this.newsletter,
        organizationDomain: this.$currentDomain,
        callbackPath: 'email-confirm',
        "processId": this.process_id,
        "groupId": null,
      };

      // console.log('registrationData: ', registrationData);
      this.$store.dispatch(REGISTER, registrationData)
          .then((response) => {
            // console.log('Risposta dalla promise register: ', response);
            if(response.status == 200 || response.status == 201) {
              // this.$refs["modal-anonymous-register"].show();
              this.confirm_send = true;
            } else {
              Swal.fire(this.$t("REGISTERFROMANONYMOUS.TITLE3"), this.$t("REGISTERFROMANONYMOUS.ERR2") + response.status, "info");
            }
            submitButton.classList.remove("spinner", "spinner-light", "spinner-right");
          })
        .catch((err) => {
          // // console.log('Risposta dalla promise register in catch: ', err);
          // let title = err.data.error.replace("<AppUser>","User");
          // let message = err.data.message.replace("<AppUser>","User")
          // Swal.fire(title, message, "info");

          // console.log('Errore:', err.data);
          let code = err.response.data.message.split('|')[0].split(':')[1];
          // console.log('Error code', code);
          // console.log(messages);

          let textMEssage = this.$t('MESSAGEAUTH.0');

          if(code.toString()) {
            textMEssage = this.$t('MESSAGEAUTH.' + code.toString());
          }

          Swal.fire({
            title: this.$t("REGISTERFROMANONYMOUS.TITLE2"),
            text: textMEssage,
            icon: "error",
            confirmButtonClass: "btn btn-secondary"
          });

          submitButton.classList.remove("spinner", "spinner-light", "spinner-right");
        });
    },
  }
};
</script>
